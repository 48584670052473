<template>
  <!-- 角色管理 -->
  <div class="container">
    <div class="head">
      <div class="dataOper">
        <div class="OperInput">
          <div class="search_box">
            <div class="search_main">
              <div class="status">
                <div>手机号</div>
                <div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="queryinfo.mobile"
                    clearable
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>姓名</div>
                <div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="queryinfo.realName"
                    clearable
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>选择日期</div>
                <div class="time">
                  <el-date-picker
                    v-model="queryinfo.loginTime"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd 00:00:00"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="search_main" style="margin-left:40px">
              <div style="display: flex">
                <div class="demandBtn" @click="search">
                  <img src="../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <el-card class="body">
      <div class="dataTable">
        <el-table
          style="font-family: PingFangRoutine"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column prop="loginTime" label="登录时间"> </el-table-column>
          <el-table-column prop="realName" label="真实姓名">
          </el-table-column>
          <el-table-column prop="mobile" label="联系电话"></el-table-column>
          <el-table-column prop="departmentName" label="部门名称"></el-table-column>
          <el-table-column prop="hostIp" label="访问ip"></el-table-column>
        </el-table>
      </div>
    </el-card>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>

  </div>
</template>
<script>
import { syslogLoginList } from "@/api/syslog";
import "../../font-style/font.css";
export default {
  data() {

    return {
      queryinfo: {
        mobile: "", //输入框电话号
        realName: "", // 输入框姓名
        loginTime:"", //时间
        currPage:'', //分页
      },
      tableData: [], // 表格数据
      total: null, // 分页总条数
    };
  },
  activated() {
    this.list();
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //日志列表
    list() {
      syslogLoginList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
        this.queryinfo.currPage=''
      });
    },

    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },

    //搜索
    search() {
      this.queryinfo.currPage=""
      this.total=0
      this.list();
    },

    // 重置
    reset() {
      this.total=0
      this.queryinfo.mobile = "";
      this.queryinfo.realName = "";
      this.queryinfo.loginTime="";
      this.queryinfo.currPage=''
      this.list();
    },

  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/common.less";
#btn {
  width: 90px;
}
/deep/ .cell{
    text-align: center;
}
/deep/ .head_box .el-card__body{
  padding: 10px 20px 20px 20px;;
}
.head{
  padding-left: 20px;
  padding-top: 6px;
  padding-bottom: 18px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
</style>
